<template>
    <!-- 业务统计-交易记录 -->
    <div id="userManageList" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="24">
                    <el-col style="width:20%;position: relative;">
                        <span class="label">交易时间:</span>
                        <el-date-picker
                            v-model="form.dateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            style="width:70%;"
                        ></el-date-picker>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">交易类型:</span>
                        <el-select
                            v-model="form.tag"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in tags"
                                :key="item.content"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">订单状态:</span>
                        <el-select
                            v-model="form.orders"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in order"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label" style="color:#fff">关键字查询:</span>
                        <el-input
                            style="width:70%;"
                            v-model="form.txt"
                            placeholder="请输入关键字查询"
                            @keyup.enter.native="onSubmit"
                        ></el-input>
                    </el-col>
                    <el-col style="width:20%" class="search-btn">
                        <div style="width:100%;display:inline-block;text-align: right;">
                            <el-button type="primary" plain @click="onSubmit">搜索</el-button>
                            <el-button type="primary" plain @click="exportLists(list)">导出</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <div class="table-box">
                        <div class="i-block table-item">总交易额:<span class="c06">{{sum_amount}}</span></div>
                        <div class="i-block table-item">支出:<span class="cf5">{{spend_amount}}</span></div>
                    </div>
                    <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        style="width: 100%"
                        :height="contentHeight - 54"
                        @selection-change="handleSelectionChange"
                        v-loading="loading"
                    >>
                        <!-- 勾选 -->
                        <el-table-column
                            type="selection"
                            width="60">
                        </el-table-column>
                        <el-table-column prop="order_no" label="交易单号"></el-table-column>
                        <el-table-column prop="title" label="订单名称"></el-table-column>
                        <el-table-column prop="username" label="用户"></el-table-column>
                        <el-table-column prop="type_price" label="金额（元）" ></el-table-column>
                        <el-table-column prop="addtime" label="交易时间"></el-table-column>
                        <el-table-column prop="trader_type" label="类型"></el-table-column>
                        <el-table-column prop="order_status" label="订单状态"></el-table-column>
                        <el-table-column prop="trader_status" label="交易状态"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { TransactionRecordsY } from "../../api/api";
import { TreaderTypeY } from "../../api/api";
import { OrderStatusY  } from "../../api/api";
import { ExportTraders } from "../../api/api";
import { constants } from "crypto";
import { truncate } from 'fs';
export default {
    components: {
    },
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form: {
                dateTime: [], //日期
                tag:'',//症状标签
                orders:'',//订单状态
                txt: "" //文字
            },
            tags:[],//交易类型标签列表
            order:[],//订单状态
            list: [], //表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip: "系统正在检索中…",
            loading:false,
            selectUser:[],

            // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName:"",//接口键
            // 操作性按钮弹框值   end
             sum_amount:0,//总交易额
            spend_amount:0,//支出金额
        };
    },
    created() {
        this.getTags();
        this.getOrder();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };

        this.getUserList();
    },

    methods: {
        //获取用户列表
        getUserList() {
            var that = this;
            var db = {
                from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                txt: this.form.txt,
                area_id: this.form.area_id, //常住地址
                doctor_info_id:this.form.doctor_info_id,//家庭医生
                pnums: this.psize,
                pages: this.pages
            };
            this.loading = true;
            this.$axios.post(TransactionRecordsY, db).then(
                res => {
                    this.loading = false;
                    if (res.data.code == 1) {
                       if (res.data.data.list.length > 0) {
                            this.list = res.data.data.list;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = "暂无记录";
                        }
                        this.spend_amount=res.data.data.spend_amount
                        this.sum_amount=res.data.data.sum_amount
                    } else {
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = "暂无搜索结果";
                    }
                },
                err => {
                    this.loading = false;
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = "暂无搜索结果";
                }
            );
        },
        //获取交易类型
        getTags() {
            var that = this;

            this.$axios.post(TreaderTypeY, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.tags = res.data.data;
                        } else {
                            this.tags = [];
                        }
                    } else {
                        this.tags = [];
                    }
                },
                err => {
                    this.tags = [];
                }
            );
        },
        //获取订单状态
        getOrder() {
            var that = this;

            this.$axios.post(OrderStatusY, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.order = res.data.data;
                        } else {
                            this.order = [];
                        }
                    } else {
                        this.order = [];
                    }
                },
                err => {
                    this.order = [];
                }
            );
        },
        //查看跳转
        skipInfo(id) {
            this.$router.push({
                path: "viewInfo",
                query: { id: id }
            });
        },
        //搜索
        onSubmit() {
            this.pages = 1;
            this.getUserList();
        },
        handleCurrentChange(val) {
            this.pages = val;
            this.getUserList();
        },
        handleSizeChange() {
            this.psize = val;
            this.getUserList();
        },
        //被勾选中的用户
        handleSelectionChange(val) {
            this.selectUser = val;
        },
        //导出
        exportLists() {
            var arr = this.selectUser;
            var ids = [];
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    ids.push(arr[i].log_id);
                }

                var db = {
                    log_ids: ids.join(","),
                    from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                };
                this.$axios
                    .post(ExportTraders, db, {
                        responseType: "arraybuffer"
                    })
                    .then(
                        res => {
                            let blob = new Blob([res.data], {
                                type: "application/vnd.ms-excel"
                            });
                            const fileName =res.headers["content-disposition"].match(/filename=(\S*).xls/)[1] ; 
                            const elink =document.createElement('a');
                            elink.download = JSON.parse(fileName)+".xls";
                            elink.href = window.URL.createObjectURL(blob);
                            elink.click();
                            window.URL.revokeObjectURL(elink.href);
                        },
                        err => {}
                    );
            } else {
                this.$message.info("至少选择一个交易进行导出");
            }
        },
    }
};
</script>
<style lang="scss">
.label {
    font-size: 14px;
    color: #666666;
    margin-right: 10px;
}
.search-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.table {
    height: calc(100vh - 167px - 100px - 40px - 20px);
    .props-rows {
        padding: 10px 0px 10px 45px;
        .title {
            color: #999999;
            margin-right: 20px;
        }
    }
}
.rows-tag {
    color: #666666;
    margin-right: 20px;
}
.table-item{
    font-size: 18px;
    color: #666666;
    letter-spacing: 0;
    padding: 20px 30px 10px 30px;
    span{
        margin-left: 10px;
    }
}
</style>
